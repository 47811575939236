import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // scrollBehavior(to, from, savedPosition) {
  //   // 否则总是滚动到顶部
  //   return { top: 0 };
  // },
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/views/index.vue')
    },
    {
      path: '/detail/:id',
      name: 'detail',
      component: () => import('@/views/details.vue')
    },
	{
	  path: '/video/:id',
	  name: 'video',
	  component: () => import('@/views/video.vue')
	}
  ]
})

export default router
