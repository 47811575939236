// stores/counterStore.js
import { defineStore } from 'pinia'

export const useMobileStore = defineStore({
  id: 'mobile',
  state: () => ({
    isMobile: false,
    userInfo: null,
    token: '',
  }),
  actions: {
    setMobile(val: boolean) {
      this.isMobile = val
    },
    setUserInfo(val: any) {
      this.userInfo = val
    },
    setToken(val: any) {
      this.token = val
    },
  },
})