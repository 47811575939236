<template>
  <transition name="fade" mode="out-in">
    <RouterView />
  </transition>
  <span v-show="show" class="to-top" @click="handlerScrollTop">
    <img src="@/assets/img/up.svg" alt="">
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { throttle } from "lodash";
import { RouterView } from 'vue-router'
import { useMobileStore } from '@/pinia/index.ts'

const mobileStore = useMobileStore()

const handlerScrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}
let show = ref(false)
window.addEventListener('scroll', throttle(() => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  show.value = scrollTop > 300
}, 500));

const setHtmlFontSize = () => {
  const screenWidth = document.body.clientWidth
  const htmlDom = document.getElementsByTagName('html')[0]
  if (screenWidth <= 750) {
    htmlDom.style.fontSize = '40px'
    htmlDom.classList.add('mobile')
    mobileStore.setMobile(true)
  } else if (screenWidth > 750 && screenWidth < 1200) {
    htmlDom.style.fontSize = '55px'
    htmlDom.classList.remove('mobile')
    mobileStore.setMobile(false)
  } else if (screenWidth >= 1200) {
    htmlDom.style.fontSize = '75px'
    htmlDom.classList.remove('mobile')
    mobileStore.setMobile(false)
  }
}
setHtmlFontSize()
window.addEventListener('resize', setHtmlFontSize);
</script>

<style lang="scss" scoped>
.to-top {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 4px 20px 0px #0000000C;
  border-radius: 50%;
  position: fixed;
  right: 10vw;
  bottom: 200px;
  z-index: 999;
  cursor: pointer;
  img {
    width: 20px;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
